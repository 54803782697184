import proj4 from "proj4";
import OlMap from "ol/Map";
import OlView from "ol/View";
import OlLayerTile from "ol/layer/Tile";
import * as OlProjProj4 from "ol/proj/proj4";
import * as OlProj from "ol/proj";
import * as OlExtent from "ol/extent";
import OlTilegridWMTS from "ol/tilegrid/WMTS";
import OlSourceVector from "ol/source/Vector";
import OlFormatGeoJSON from "ol/format/GeoJSON";
import OlLayerVector from "ol/layer/Vector";
import OlStyleStyle from "ol/style/Style";
import OlStyleFill from "ol/style/Fill";
import OlStyleStroke from "ol/style/Stroke";
import OlStyleIcon from "ol/style/Icon";
import OlGeomPoint from "ol/geom/Point";
import OlFeature from "ol/Feature";
import OlControlZoom from "ol/control/Zoom";

import { getMapConfig, createTileSource } from "javascripts/map-utils";

// Register a new projection we can use in OpenLayers
proj4.defs("EPSG:25832", "+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs"); //UTM for Denmark
proj4.defs("EPSG:32629", "+proj=utm +zone=29 +ellps=WGS84 +units=m +no_defs"); //UTM for Faroe Islands
OlProjProj4.register(proj4);

export class ArticleMap {
  constructor({
    element,
    geoJsonUrl,
    position,
    highlightedFeature,
    pinIconUrl,
    mapConfigName,
  }) {
    this.element = element;
    this.mapConfig = getMapConfig(mapConfigName);
    this.extent = this.mapConfig.extent;
    this.projectionCode = this.mapConfig.projectionCode;
    this.projection = OlProj.get(this.projectionCode);
    this.position = position;
    this.highlightedFeature = highlightedFeature;
    this.highlightedExtent = undefined;
    this.zoomedToFeature = false;
    this.pinIconUrl = pinIconUrl;
    this.focusPosition = undefined;

    this.projection.setExtent(this.extent);

    this.source = new OlSourceVector({
      format: new OlFormatGeoJSON(),
      url: geoJsonUrl,
    });

    // Wait for all features to finish loading
    if (this.highlightedFeature != null) {
      const { key, value } = this.highlightedFeature;

      this.source.on("change", () => {
        if (this.source.getState() !== "ready") {
          return;
        }

        this.highlightedExtent = OlExtent.createEmpty();
        this.source.forEachFeature((feature) => {
          if (feature.getProperties()[key] !== value) {
            return;
          }

          // Update bounding box of highlighted areas so we can keep them in
          // view when zooming in or out
          const extent = feature.getGeometry().getExtent();
          this.highlightedExtent = OlExtent.extend(
            this.highlightedExtent,
            extent,
          );
        });

        this.focusPosition = OlExtent.getCenter(this.highlightedExtent);
      });
    }

    this.layer = this._createMainVectorLayer();

    this.locationsSource = new OlSourceVector();
    this.locationsLayer = new OlLayerVector({
      source: this.locationsSource,
      style: new OlStyleStyle({
        image: new OlStyleIcon({
          anchor: [0.5, 0.94],
          scale: 0.7,
          src: this.pinIconUrl,
        }),
      }),
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      // maxResolution: this.highlightedFeature == null ? 10000 : 64
    });

    this.tileLayer = new OlLayerTile({
      source: createTileSource(this.mapConfig),
      name: this.mapConfig.layer,
      title: "Skærmkort dæmpet",
      maxResolution: 256,
    });
  }

  show() {
    if (this.position != null) {
      const { lat, lon } = this.position;
      this.focusPosition = OlProj.fromLonLat([lon, lat], this.projectionCode);

      // Show a pin at the position if we don't have a highlighted feature
      if (this.position != null && this.highlightedFeature == null) {
        const point = new OlGeomPoint(this.focusPosition);
        const feature = new OlFeature(point);
        this.locationsSource.addFeature(feature);
      }
    }

    const view = new OlView({
      zoom: 0,
      resolutions: this.mapConfig.tileGrid?.getResolutions(),
      projection: this.projection,
      extent: this.extent,
      // By constraining the extent only to the view center we make it
      // possible to zoom out enough to show the whole extent, and to
      // pan a little outside the extent.
      constrainOnlyCenter: true,
    });

    this.map = new OlMap({
      layers: [this.tileLayer, this.layer, this.locationsLayer],
      target: this.element,
      view,
      controls: [new OlControlZoom({})],
      interactions: [],
    });

    // Fit the entire map extent in the view with a little padding on each side
    // plus enough padding on the right side to ensure no parts of the map are
    // behind the zoom buttons, and enough on the top to account for a possible
    // pin icon
    view.fit(this.extent, {
      padding: [30, 45, 5, 5],
    });

    const countryCenter = view.getCenter();

    // If we don't have a focus position just default to the country center.
    // This ensures the center of the country is focused when zooming in or out.
    if (this.focusPosition == null) {
      this.focusPosition = countryCenter;
    }

    const zoomIn = this.element.querySelector(".ol-zoom-in");
    zoomIn.addEventListener("click", (e) => {
      view.cancelAnimations();
      zoomIn.disabled = true;
      zoomOut.disabled = true;

      let targetResolution = view.getResolution() / 4;
      if (this.highlightedFeature) {
        const marginFactor = 1.1;
        const minResolution =
          view.getResolutionForExtent(this.highlightedExtent) * marginFactor;
        if (targetResolution <= minResolution) {
          targetResolution = minResolution;
          this.zoomedToFeature = true;
        }
      }
      const i = Math.max(
        Math.min(
          100 - targetResolution / this.mapConfig.positionInterpolationFactor,
          1,
        ),
        0,
      );

      view.animate(
        {
          center: [
            countryCenter[0] * (1 - i) + this.focusPosition[0] * i,
            countryCenter[1] * (1 - i) + this.focusPosition[1] * i,
          ],
          resolution: targetResolution,
          duration: 250,
        },
        () => {
          zoomIn.disabled = false;
          zoomOut.disabled = false;
        },
      );
    });

    const zoomOut = this.element.querySelector(".ol-zoom-out");
    zoomOut.addEventListener("click", (e) => {
      view.cancelAnimations();
      this.zoomedToFeature = false;

      const i = Math.max(
        Math.min(
          100 -
            (view.getResolution() * 4) /
              this.mapConfig.positionInterpolationFactor,
          1,
        ),
        0,
      );
      zoomIn.disabled = true;
      zoomOut.disabled = true;

      view.animate(
        {
          center: [
            countryCenter[0] * (1 - i) + this.focusPosition[0] * i,
            countryCenter[1] * (1 - i) + this.focusPosition[1] * i,
          ],
          resolution: view.getResolution() * 4,
          duration: 250,
        },
        () => {
          zoomIn.disabled = false;
          zoomOut.disabled = false;
        },
      );
    });
  }

  _createMainVectorLayer() {
    return new OlLayerVector({
      source: this.source,
      style: (feature, resolution) => {
        let isHighlighted = false;
        if (this.highlightedFeature != null) {
          const { key, value } = this.highlightedFeature;
          isHighlighted = feature.getProperties()[key] === value;
        }

        const { fillColor, strokeColor } =
          this.highlightedFeature != null
            ? getMapColorsWithHighlightedFeature(
                resolution,
                isHighlighted,
                this.zoomedToFeature,
              )
            : getMapColorsWithoutHighlightedFeature(resolution);

        return new OlStyleStyle({
          fill: new OlStyleFill({ color: fillColor }),
          stroke: new OlStyleStroke({
            color: strokeColor,
            width: isHighlighted ? 2.0 : 0.5,
          }),
        });
      },
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      minResolution: this.highlightedFeature != null ? 0 : 128,
    });
  }
}

/**
 * Get fill color and stroke color for a map feature (drawn in the main vector
 * layer) when no feature is highlighted
 */
function getMapColorsWithoutHighlightedFeature(resolution) {
  const alpha = Math.max(Math.min((resolution - 128) / 128, 1), 0);
  return {
    fillColor: `rgba(255, 246, 205, ${alpha})`,
    strokeColor: `rgba(188, 179, 152, ${alpha})`,
  };
}

/**
 * Get fill color and stroke color for a map feature (drawn in the main vector
 * layer) when some feature is highlighted
 *
 * @param featureIsHighlighted Get the colors for a highlighted feature
 *        (otherwise, the colors for one that's not highlighted itself)
 */
function getMapColorsWithHighlightedFeature(
  resolution,
  featureIsHighlighted,
  isZoomedToFeature,
) {
  if (featureIsHighlighted) {
    const alpha = isZoomedToFeature ? 0 : 1;
    const fillColor = `rgba(49, 89, 137, ${alpha})`;
    return {
      fillColor,
      strokeColor: fillColor,
    };
  }

  if (isZoomedToFeature) {
    return {
      fillColor: "rgba(0, 0, 0, 0.5)",
      strokeColor: "rgba(0, 0, 0, 0)",
    };
  }

  return {
    fillColor: "rgba(255, 246, 205, 1)",
    strokeColor: "rgba(188, 179, 152, 1)",
  };
}

import OlSourceWMTS from "ol/source/WMTS";
import OlSourceWMS from "ol/source/TileWMS";
import OlTilegridWMTS from "ol/tilegrid/WMTS";

const mapConfigs = {
  denmark: {
    layer: "topo_skaermkort_daempet",
    baseUrl: "https://api.dataforsyningen.dk/topo_skaermkort_daempet_DAF",
    projectionCode: "EPSG:25832",
    extent: [441000, 6049000, 893000, 6403000],
    maxZoom: 6.0,
    showPlacesText: "Vis steder i kommunen",
    tileGrid: new OlTilegridWMTS({
      extent: [120000, 5900000, 1000000, 6500000],
      resolutions: [
        1638.4, 819.2, 409.6, 204.8, 102.4, 51.2, 25.6, 12.8, 6.4, 3.2, 1.6,
        0.8, 0.4, 0.2,
      ],
      matrixIds: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
      ],
    }),
    positionInterpolationFactor: 10,
  },
  faroe: {
    layer: "ftk_f20",
    baseUrl: "https://api.dataforsyningen.dk/ftopo",
    projectionCode: "EPSG:32629",
    extent: [567890.0, 6801756.910736, 644892.605501, 6920761.665493],
    maxZoom: 10.0,
    showPlacesText: "Vis steder på øen",
    positionInterpolationFactor: 20,
  },
  greenland: {
    layer: "Gtk_g250",
    baseUrl: "https://api.dataforsyningen.dk/gtopo",
    projectionCode: "EPSG:32624",
    extent: [-400000, 6400000, 1320000, 9400000],
    maxZoom: 5.0,
    showPlacesText: "Vis steder i kommunen",
    positionInterpolationFactor: 30,
  },
};

/**
 * Get a config object with various map settings
 *
 * @param configName either 'denmark' or 'faroe'
 */
function getMapConfig(configName) {
  return mapConfigs[configName];
}

/**
 * Return an OpenLayers WMTS layer source.
 *
 * @param mapConfig a config object that includes 'baseUrl', 'layer' and
 *     'tileGrid', such as the one returned by getMapConfig
 */
function createTileSource(mapConfig) {
  return mapConfig.tileGrid
    ? new OlSourceWMTS({
        crossOrigin: "Anonymous",
        url: `${mapConfig.baseUrl}?token=0304c3aa9e3edfacff278f0b72e2bb48`,
        layer: mapConfig.layer,
        matrixSet: "View1",
        format: "image/jpeg",
        tileGrid: mapConfig.tileGrid,
        style: "default",
        size: [256, 256],
      })
    : new OlSourceWMS({
        crossOrigin: "Anonymous",
        url: `${mapConfig.baseUrl}?token=0304c3aa9e3edfacff278f0b72e2bb48`,
        params: {
          LAYERS: mapConfig.layer,
          TRANSPARENT: "TRUE",
        },
      });
}

export { getMapConfig, createTileSource };
